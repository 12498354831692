//General colors
$blue: #025dc7;
$blue-hover: #0c4283;
$black: #222222;
$green: #8ac63b;
$light-gray: #f5f5f5;
$gray: #d7d7d7;
$dark-gray: #525252;
$btn-blue-hover: #024ba1;

$font-family: 'Source Sans Pro', sans-serif;

// $Bootstrap
@import 'vendor/bootstrap/core';
// $Fontawesome
@import 'vendor/font-awesome/main';
// $Parts
@import 'parts/index';
