// Color Palette
// Based on the official Sass styleguide
// ===================================================================
// Descriptive colors
// ===================================================================
$white: #ffffff !default;
$black: #000000 !default;
$transparent-white: rgba(255, 255, 255, 0.2);

.color-transparent-white {
    color: $transparent-white;
}

.color-white {
    color: $white;
}

.color-black {
    color: $black;
}

// ===================================================================
// Theme colors
// ===================================================================
// ===================================================================
// Main elements colors
// ===================================================================
// ========================================================
//  Gray Material Colors
// ========================================================
$gray-50: #fafafa;
$gray-100: #f5f5f5;
$gray-200: #eeeeee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;
.color-gray-50 {
    color: $gray-50;
}

.color-gray-100 {
    color: $gray-100 !important;
}

.color-gray-200 {
    color: $gray-200;
}

.color-gray-300 {
    color: $gray-300;
}

.color-gray-400 {
    color: $gray-400;
}

.color-gray-500 {
    color: $gray-500;
}

.color-gray-600 {
    color: $gray-600;
}

.color-gray-700 {
    color: $gray-700;
}

.color-gray-800 {
    color: $gray-800;
}

.color-gray-900 {
    color: $gray-900;
}

// links
// background colors
.bg-gradient {
    background-image: linear-gradient(to right bottom, #2a1d39, #3b2952, #4d366d, #5f4389, #7151a6);
}

.bg-gray-50 {
    background-color: $gray-50;
}

.bg-gray-100 {
    background-color: $gray-100;
}

.bg-gray-200 {
    background-color: $gray-200;
}

.bg-gray-300 {
    background-color: $gray-300;
}

.bg-gray-400 {
    background-color: $gray-400;
}

.bg-gray-500 {
    background-color: $gray-500;
}

.bg-gray-800 {
    background-color: $gray-800;
}

.bg-gray-900 {
    background-color: $gray-900;
}