/*
base styles
*/
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');

body {
    font-family: $font-family;
    min-width: 1140px;
    & > .container {
        max-width: 1140px;
        padding-bottom: 3rem;
        min-width: 1140px;
        width: 1140px;
        .container {
            max-width: 100%;
        }
    }
}

a {
    &:hover, &:focus {
        color: $blue-hover;
        text-decoration: none;
    }
}

p {
    line-height: 1.3;
}
