//==============================================
// Layout/footer
//============================================== 

footer {
    margin: 0;
    position: relative;
    z-index: 50;
    .footer-help-wrapper {
        position: relative;
        .footer-help {
            bottom: 0;
            position: absolute;
            width: 100%;
        }
    }
    .footer-help-toggler-wrapper {
        border-bottom: 3px solid $blue;
        position: relative;
        .footer-help-toggler {
            border: 20px solid rgba(0,0,0,0);
            border-top: 0 solid;
            border-bottom: 40px solid $blue;
            color: $white;
            display: block;
            height: 0;
            text-align: center;
            width: 160px;
            &:hover {
                border-bottom-color: $btn-blue-hover;
                text-decoration: none;
            }
            .fas {
                font-size: 0.9rem;
            }
            &.collapsed {
                .fa-chevron-down {
                    transform: rotateX(180deg);
                }
            }
            span {
                display: block;
                margin-top: 0.5rem;
            }
        }
    }
    #collapseHelp {
        background-color: $gray-100;
    }
    
    .footer-help-cards {
        background-color: $gray-100;
        font-size: 0.9rem;
        max-width: 750px;
        margin: 0 auto;
        padding: 1rem 1rem 0;
        .footer-help-card {
            align-items: center;
            border: 1px solid $blue;
            display: flex;
            height: 90px;
            font-weight: bold;
            justify-content: center;
            margin: 0.3rem 0;
            padding: 0.9rem;
            text-align: center;
            text-decoration: none;
            width: 125px;
            &:hover {
                background-color: $blue;
                color: $white;
            }
        }
    }

    .footer-bottom {
        background-color: $gray-100;
        padding: 1rem 0 1.5rem;
        .nav {
            &-item {
                border-right: 1px solid $black;
                margin-bottom: 0.5rem;
                &:last-child() {
                    border-right: none;
                }
            }
            a {
                color: $black;
                font-weight: bold;
                line-height: 1;
                padding-bottom: 0;
                padding-top: 0;
                &:hover {
                    color: $blue;
                }
            }
        }
    }
}