//==============================================
// Module/modules
//==============================================

// Here you can styling other small modules

.card {
    border: none;
    border-radius: 0;
    margin-bottom: 1rem;
    &-header {
        background-color: $green;
        border: none;
        border-radius: 0;
        color: $white;
        h3 {
            font-size: 1.3rem;
            font-weight: 600;
            margin-bottom: 0;
        }
    }
    &-body {
        background-color: $gray-100;
        padding-bottom: 0;
        .card-articles {
            .card-article {
                border-top: 1px solid $black;
                margin-top: 2rem;
                padding-top: 2rem;
                &-image {
                    margin-bottom: 1rem;
                }
                &-title {
                    font-size: 1.3rem;
                    font-weight: 300;
                }
                &-content {
                    font-weight: 300;
                }
                .learn-more {
                    font-weight: 700;
                    .fas {
                        font-size: 0.7rem;
                        margin-left: 0.2rem;
                        transition: margin 200ms;
                    }
                    &:hover {
                        .fas {
                            margin-left: 0.3rem;
                        }
                    }
                }
                &:first-child {
                    border-top: none;
                    margin-top: 0;
                    padding-top: 0;
                }
            }
        }
        .card-links {
            &-title {
                font-size: 1.3rem;
                font-weight: 300;
                margin-bottom: 1rem;
            }
            &-content {
                padding-bottom: 2rem;
                a {
                    display: flex;
                    font-weight: 700;
                }
            }
        }
    }
}

.info-popup-wrapper {
    position: relative;
    .info-popup {
        background-color: $white;
        border: 2px solid $blue;
        display: none;
        font-size: 0.7rem;
        font-weight: 400;
        max-width: 400px;
        min-width: 300px;
        padding: 1rem 1rem 0.5rem;
        position: absolute;
        text-align: left;
        top: -10px;
        z-index: 3;
        h5 {
            color: $blue;
            font-size: 0.9rem;
        }
        .close {
            color: $blue;
            font-size: 1.4rem;
            font-weight: 400;
            opacity: 1;
            position: relative;
            top: -6px;
        }
    }
}


.scorecards {
    margin: 1rem;
    .scorecard {
        margin-bottom: 2rem;
        margin-top: 1rem;
        .scorecard-item {
            display: flex;
            font-size: 0.8rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
            &-title {
                line-height: 1;
                padding-right: 1rem;
                text-align: right;
                width: 33%;
            }
            &-bar-wrapper {
                display: flex;
                height: 1rem;
                width: 60%;
            }
            &-bar {
                background-color: $blue;
                width: 0px;
            }
            &-percentage {
                font-size: 0.8rem;
                line-height: 1;
                padding-left: 0.5rem;
            }
        }
    }
}

.vertical-title-box {
    &-title {
        background-color: $blue;
        color: $white;
        padding: 0.4rem 0.1rem;
        text-align: center;
        span {
            font-weight: 600;
            transform: rotate(-180deg);
            writing-mode: tb-rl;
        }
    }
    &-content {

    }
}
