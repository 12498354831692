//==============================================
// Module/buttons
//==============================================

.btn {
	border-radius: 0;
	&.btn-lg {
		font-size: 0.8rem;
		font-weight: 600;
        padding: 0.4rem 2rem;
	}
	&:hover, &:focus {
		color: $white;
	}
}
