//==============================================
// Utilites/Spacers
//==============================================
/*
 Remember you can use Bootstrap Spacing utility classes: https://getbootstrap.com/docs/4.0/utilities/spacing/
*/

// SASS Margin and Padding Helpers generated with this scss loop: https://gist.github.com/jacurtis/30da4bf9a6c9b9b5cc0aebac512ca7c9
$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100);
$sides: (top, bottom, left, right, all);
@each $space in $spaceamounts {
    @each $side in $sides {
        @if $side=='all' {
            .m#{$space} {
                margin: #{$space}px;
            }
            .p#{$space} {
                padding: #{$space}px;
            }
        }
        @else {
            .m#{str-slice($side, 0, 1)}#{$space} {
                margin-#{$side}: #{$space}px;
            }
            .p#{str-slice($side, 0, 1)}#{$space} {
                padding-#{$side}: #{$space}px;
            }
        }
    }
}