//==============================================
// Layout/sections
//============================================== 

.l-section {}

.login-wrapper {
    margin: 4rem auto;
    width: 450px;
    form {
        padding: 0.5rem 3rem;
        .form-control {
            font-size: 0.9rem;
            height: calc(2rem + 2px);
        }
        .form-row {
            align-items: center;
        }
    }
}

.dashboard-content-new {
    margin: 0 auto;
    h4 {
        font-size: 1.3rem;
        font-weight: 300;
        margin-bottom: 1rem;
    }
}

.peer-group {
    &-dropdowns-wrapper {
        .round-number {
            margin-bottom: 0.4rem;
            margin-right: 1rem;
        }
        .peer-group-dropdowns {
            border-bottom: 2px solid $blue;
            display: inline-block;
            margin-bottom: 2rem;
            &-secondary {
                .dropdown {
                    .dropdown-menu {
                        padding-bottom: 1.5rem;
                        width: 210px;
                        a {
                            display: flex;
                            white-space: normal;
                        }
                    }
                    &-toggle {
                        font-size: 1rem;
                        padding-right: 2rem;
                        .fa-chevron-down {
                            font-size: 0.7rem;
                        }
                    }
                    &:last-child {
                        .dropdown-toggle {
                            padding-right: 0.4rem;
                        }
                    }
                }
            }
        }
        .dropdown {
            display: inline-block;
            &-toggle {
                color: $black;
                font-size: 1.7rem;
                font-weight: bold;
                padding-bottom: 0.4rem;
                padding-right: 0.3rem;
            }
            &-item {
                font-size: 0.9rem;
                font-weight: 600;
            }
            &.show {
                .dropdown-toggle {
                    .fa-chevron-down {
                        transform: rotateX(180deg);
                    }
                }
            }
        }
    }
    &-panel {
        background-color: $light-gray;
        padding-bottom: 2rem;
        &-filters {
            .form-group {
                margin-right: 1rem;
            }
        }
        &-results {
            font-size: 1.4rem;
            .round-number {
                margin-left: 0.4rem;
                margin-right: 0.3rem;
            }
        }
    }
    .data-settings-card {
        height: 100%;
        a {
            align-items: center;
            border: 1px solid $black;
            color: $blue;
            display: flex;
            font-weight: 600;
            height: 100%;
            line-height: 1.2;
            min-height: 8rem;
            padding: 0.3rem;
            position: relative;
            text-align: center;
            .data-settings-card-text {
                display: block;
            }
            .data-settings-card-text-hover {
                display: none;
                font-size: 0.8rem;
            }
            &:after {
                background: transparent url('../img/icon-filter-ext.png') no-repeat;
                content: '';
                height: 20px;
                position: absolute;
                right: 5px;
                top: 5px;
                width: 20px;
            }
            &:hover {
                background-color: $light-gray;
                border: 4px solid $blue;
                font-weight: 400;
                height: 120%;
                left: -10%;
                top: -10%;
                width: 120%;
                z-index: 2;
                .data-settings-card-text {
                    display: none;
                }
                .data-settings-card-text-hover {
                    display: block;
                }
            }
        }
    }
    &-table-results {
        .peer-group-panel-results {
            padding-right: 0.8rem;
            .table-caption {
                background-color: $gray;
                color: $dark-gray;
                font-size: 0.8rem;
                font-style: italic;
                font-weight: 400;
                padding: 0.4rem;
                text-align: center;
            }
        }
    }
    &-table {
        color: $dark-gray;
        font-size: 0.7rem;
        max-width: 99%;
        tbody {
            tr:nth-child(even) {
                background: $white;
            }
        }
        thead {
            border-bottom: 1px solid $black;
        }
        tr {
            td, th {
                border-right: 1px solid $black;
                padding: 0.3rem;
                text-align: center;
                &:last-child {
                    border-right: none;
                }
            }
            td {
                font-weight: 600;
            }
            &.cell-divider {
                border-bottom: 6px solid $blue;
            }
        }
    }
    .request-services-cta {
        border-top: 3px solid $blue;
        margin: 0 1.5rem;
        padding: 1.2rem 1rem 1rem;
        text-align: center;
    }
}

.create-step {
    margin: 2rem auto 2rem;
    &-title {
        h4 {
            display: inline-block;
            font-size: 1.1rem;
            font-style: italic;
            font-weight: 600;
            margin-left: 0.5rem;
            .input-text-styled {
                min-width: 19rem;
            }
        }
    }
    &-content {
        font-size: 0.7rem;
        height: 100%;
        margin-left: 2.2rem;
        label {
            color: $dark-gray;
            font-style: italic;
        }
        input[type="text"] {
            border: 1px solid $dark-gray;
        }
        select[multiple="multiple"] {
            border: 1px solid $dark-gray;
            height: 100%;
            min-height: 8rem;
            width: 100%;
            optgroup[label] {
                background-color: $gray;
                font-weight: 400;
                padding-top: 0.2rem;
                option {
                    padding-left: 0;
                    &:first-child {
                        margin-top: 0.2rem;
                    }
                }
            }
            option {
                background-color: $white;
                font-weight: 600;
                padding: 0.4rem;
            }
        }
        #peer-group-gic-code {
            width: 98%;
        }
        label[for="peer-group-gic-code"] {
            white-space: nowrap;
        }
        #peer-group-gic-code-lookup {
            max-width: 5rem;
        }

        .form-check-label {
            padding-top: 0.2rem;
        }
        .criteria-financials {
            input[type="text"] {
                max-width: 8rem;
            }
        }
        .criteria-financials,
        .criteria-roles-states {
            .vertical-title-box-content {
                width: 100%;
            }
        }
        .vertical-title-box {
            height: 100%;
        }
        .step-companies {
            height: 100%;
            .vertical-title-box-content {
                height: 100%;
                width: 100%;
            }
        }
    }
    &.step-companies {
        height: 100%;
        .vertical-title-box-content {
            width: 100%;
        }
        .create-step-content {
            .step-companies-row {
                height: 80%;
            }
        }
        .btn-sm {
            font-weight: 700;
            line-height: 1.1;
        }
    }
}
