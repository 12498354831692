//==============================================
// Module/nav
//==============================================

nav {
    margin-bottom: 2rem;
}

.dropdown-toggle {
    &:after {
        display: none;
    }
    .fas {
        color: $blue;
        font-size: 0.9rem;
    }
}
.dropdown-menu {
    border: none;
}
.dropdown-divider {
    border-top: 1px solid $black;
    margin: 0.6rem 1rem;
}

.nav-section {
    .nav-link {
        color: $black;
        font-weight: bold;
        margin: 0.5rem 1rem;
        padding: 0 0 .4rem;
    }
    .nav-main {
        .nav-link {
            text-transform: uppercase;
            &.active,
            &:hover {
                color: $blue;
                border-bottom: 2px solid $blue;
            }
        }
    }
    .nav-utility {
        .nav-config {
            & > a {
                color: $blue;
                font-size: 1.5rem;
                line-height: normal;
                margin-bottom: 0rem;
                padding-bottom: 0;
                vertical-align: middle;
                .fas {
                    font-size: 1.4rem;
                }
            }
            &.show {
                .dropdown-toggle {
                    .fas {
                        color: $black;
                    }
                    &:after {
                        color: $blue;
                        display: block;
                        transform: rotateX(180deg);
                    }
                }
            }
            &-subnav {
                border: none;
                border-top: 3px solid $blue;
                left: -150px!important;
                a {
                    color: $blue;
                    font-weight: bold;
                    margin-bottom: 0.5rem;
                    padding-left: 1rem;
                    i {
                        font-size: 1.2rem;
                        margin-right: 0.5rem;
                    }
                }
            }
        }
        .nav-profile {
            &.show {
                .dropdown-toggle {
                    .fa-chevron-down {
                        transform: rotateX(180deg);
                    }
                }
            }
            &-subnav {
                border: none;
                border-top: 3px solid $blue;
                left: auto!important;
                font-size: 0.9rem;
                margin-right: 1rem;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                right: 15px;
                div,
                a {
                    margin-bottom: 0.3rem;
                    padding-bottom: 0;
                    i {
                        margin-right: 0.5rem;
                    }
                }
                div {
                    i {
                        color: $blue;
                    }
                }
                a {
                    color: $blue;
                    font-weight: bold;
                }
                .fas,
                .far {
                    font-size: 1.2rem;
                }
                .nav-profile-account-manager-title {
                    i {
                        color: $black;
                    }
                }
                .nav-profile-account-manager-name {
                    b {
                        padding-left: 2rem;
                    }
                }
                .nav-profile-account-manager-phone {
                    .fas {
                        transform: rotateY(180deg);
                    }
                }
                .nav-profile-logout {
                    .fas {
                        margin: 0 0.7rem 0 0.2rem;
                    }
                }
            }
        }
    }
}

.pager {
    &-link,
    &-jump {
        font-size: 0.9rem;
        font-weight: 600;
    }
    &-link {
        .fas {
            font-size: 0.6rem;
        }
        &-previous {
            margin-right: 0.6rem;
            .fas {
                padding-right: 0.4rem;
            }
        }
        &-next {
            margin-left: 0.6rem;
            .fas {
                padding-left: 0.4rem;
            }
        }
    }
    &-jump {
        display: flex;
        label {
            color: $dark-gray;
            margin-bottom: 0;
        }
        .form-select {
            margin-left: 1rem;
            min-width: 35px;
        }
    }
}
