//==============================================
// Module/inputs, forms, textarea
//==============================================

.form-control {
    border-color: $black;
    border-radius: 0;
    &::placeholder {
        color: $gray-500;
    }
}

.form-select {
    background: $light-gray;
    position: relative;
    select {
        background: transparent;
        border-radius: 0;
        display: inline-block;
        position: relative;
        z-index: 1;
        -webkit-appearance: none;
        &:focus {
            background: transparent;
        }
    }
    &:after {
        bottom: 0.5rem;
        color: $blue;
        content: "\f078";
        display: block;
        font-family: 'Font Awesome 5 Free';
        font-size: 0.9rem;
        font-weight: 900;
        position: absolute;
        right: 0.8rem;
        z-index: 0;
    }
    &-tiny {
        select {
            height: calc(1.5rem + 2px);
            font-size: 0.8rem;
            font-weight: 600;
            padding-top: 0.1rem;
            padding-left: 0.4rem;
        }
        &:after {
            bottom: 0.3rem;
            font-size: 0.6rem;
            right: 0.4rem;
        }
    }
}

.input-text-styled {
    border: none;
    border-bottom: 2px solid $blue;
    color: $black;
    font-size: 1.1rem;
    font-weight: 600;
    font-style: italic;
    &::placeholder {
        color: $black;
        font-weight: 600;
        font-style: italic;
        font-size: 1.1rem;
    }
}
