//==============================================
// Utilites/Typography
//==============================================

.font-bold {
	font-weight: 700;
}
.font-semibold {
	font-weight: 600;
}
.font-regular {
	font-weight: 400;
}
.font-light {
	font-weight: 300;
}

.font-normal {
	font-style: normal;
}

.icon-download {
    height: 1rem;
    line-height: 1rem;
    margin-right: 0.4rem;
    margin-top: 3px;
    width: 1rem;
    .fa-arrow-down {
        top: -3px;
        &:before {
            font-size: 0.8rem;
        }
    }
}
.round-number {
    background-color: $blue;
    color: $white;
    display: inline-block;
    height: 25px;
    line-height: 0.8;
    font-size: 1rem;
    font-style: normal;
    font-weight: bold;
    padding: 0.4rem 0.55rem;
    text-align: center;
    width: 25px;
}
